<template>
  <div class="container is-fluid">
    <div class="columns">
      <div class="column is-12">
        <h4 class="page-header">
          <b-icon icon="star"></b-icon>
          <span>{{ $ml.get('bets_placed_head') }}</span>
        </h4>
        <b-button tag="router-link" size="is-small" type="is-info" outlined :to="{ path: `/bets/settled` }">
          <span>{{ $ml.get('bets_settled_head') }}</span>
        </b-button>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-2">
        <div class="dash-plate box">
          <div class="header is-primary">{{ $ml.get('dashboard_bets_count') }}</div>
          <div class="body">
            <div class="side-icon text-primary">
              <b-icon icon="star"></b-icon>
            </div>
            <div class="side-info">
              <span>{{ new Intl.NumberFormat().format(items.length) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="dash-plate box">
          <div class="header is-success">{{ $ml.get('bets_settled_amount') }}</div>
          <div class="body">
            <div class="side-icon has-text-success">
              <b-icon icon="currency-usd"></b-icon>
            </div>
            <div class="side-info">
              <span><Money :subunits=true currency="EUR" v-bind:amount="totalStake"/></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <b-table
            :data="items"
            :paginated="true"
            :bordered="true"
            :striped="true"
            :loading="loading"
            :per-page="perPage">
          <b-table-column field="mode" width="50" :label="$ml.get('bets_mode')" sortable searchable v-slot="props">
            {{ props.row.mode }}
          </b-table-column>
          <b-table-column field="room" :label="$ml.get('bets_account')" class="rightal" sortable searchable v-slot="props">
            {{ props.row.room }}
          </b-table-column>
          <b-table-column field="strategy" :label="$ml.get('bets_strategy')" class="leftal" sortable searchable v-slot="props">
            {{ props.row.strategy }}
          </b-table-column>
          <b-table-column field="status" width="80" :label="$ml.get('bets_status')" sortable searchable v-slot="props">
            <State v-bind:state="props.row.status"/>
          </b-table-column>
          <b-table-column width="100" field="placedCoef" :label="$ml.get('bets_coef')" sortable searchable v-slot="props">
            {{ props.row.placedCoef }}
          </b-table-column>
          <b-table-column width="50" field="stake" :label="$ml.get('bets_stake')" sortable searchable v-slot="props">
            <Money v-bind:currency="props.row.currency" v-bind:amount="props.row.stake"/>
          </b-table-column>
          <b-table-column field="bookmaker" width="150" :label="$ml.get('bets_bookmaker')" class="rightal" sortable searchable
                          v-slot="props">
            <Bk :bk="props.row.bookmaker"/>
          </b-table-column>
          <b-table-column field="timeValue" width="100" :label="$ml.get('bets_time')" class="leftal" sortable searchable v-slot="props">
            {{ props.row.timeValue }}
          </b-table-column>
          <b-table-column field="event" :label="$ml.get('bets_event_line')" sortable searchable v-slot="props">
            <div class="columns">
              <div class="column is-7 rightal">
                <span>{{ props.row.homeTeam }} - {{ props.row.awayTeam }}</span> <span
                  v-if="props.row.score">[{{ props.row.score }}]</span>
              </div>
              <div class="column is-1 has-text-centered">
                <Sport v-bind:sport="props.row.sport"/>
              </div>
              <div class="column is-4 leftal">
                <BkLine :market="props.row.market" :target="props.row.target" :pivot="props.row.pivot"/>
              </div>
            </div>
          </b-table-column>
          <b-table-column field="placedTime" width="60" :label="$ml.get('bets_placed')" sortable v-slot="props">
            {{ props.row.placedTime_hr }}
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import State from "@/components/Status";
import Money from "@/components/Money";
import Vue from "vue";
import Sport from "@/components/Sport";
import BkLine from "@/components/BkLine";
import Bk from "@/components/Bk";

export default {
  name: "Placed",
  data() {
    return {
      perPage: 15,
      currentPage: 1,
      loading: false,
      items: [],
    }
  },
  components: {Sport, Bk, BkLine, State, Money},
  computed: {
    totalStake() {
      let stake = 0
      this.items.forEach((item) => {
        stake += item.euroStake
      });
      return stake
    },
  },
  methods: {
    retrieve() {
      this.loading = true
      Vue.prototype.$http.get(process.env.VUE_APP_API_HOST + `bets/placed`).then(resp => {
        this.items = resp.data.result.items
      }).catch(err => {
        this.flashMessage.error({
          title: this.$ml.get('common_request_failed'),
          message: this.$ml.get('common_request_failed_message'),
        })
      }).finally(() => {
        this.loading = false
      })
    },
  },
  mounted() {
    this.retrieve()
  },
  metaInfo() {
    return {
      title: this.$ml.get('titles_bets_placed')
    }
  }
}
</script>

<style scoped>
</style>