<template>
  <img :alt="this.bk" :title="this.bk" :src="require('@/assets/bk/' + source)" style="width: 60px">
</template>

<script>
export default {
  name: "Bk",
  props: ['bk'],
  computed: {
    source() {
      return (this.bk.replace('.CUPIS', '')) + '.svg'
    }
  }
}
</script>

<style scoped>

</style>