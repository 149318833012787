<template>
  <span>{{ lineStr }}</span>
</template>

<script>
export default {
  name: "BkLine",
  props: ['market', 'target', 'pivot'],
  data() {
    return {
      market_map: {
        'TOTAL': 'Т',
        'T1_TOTAL': 'Т1',
        'T2_TOTAL': 'Т2',
        'CORNER_TOTAL': '(угловые) Т',
        'YC_TOTAL': '(ж.карточки) Т',
        'ONE_TWO': '1X2',
        'HDP': 'Ф',
        'CORNER_HDP': '(угловые) Ф',
        'YC_HDP': '(ж.карточки) Ф',
        'EURO_HDP': '',
      },
      target_map: {
        'OVER': 'Б',
        'UNDER': 'М',
        'ONE': ' П1',
        'TWO': ' П2',
        'DRAW': ' X',
        'ONE_DRAW': ' 1X',
        'TWO_DRAW': ' X2',
        'ONE_TWO': ' 12',
        'HOME': '1',
        'AWAY': '2',
        'H1': 'П1',
        'H2': 'П2',
        'HX': 'X',
      }
    }
  },
  computed: {
    lineStr() {
      let str = this.market_map[this.market] + this.target_map[this.target]
      if (this.pivot) {
        if (this.market === 'EURO_HDP') {
          this.pivot -= 0.5
        }
        str += ' (' + (this.pivot) + ')'
      }

      return str
    }
  }
}
</script>

<style scoped>

</style>